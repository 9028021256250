.social-icon {
  font-size: 1.4rem;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0.2rem;

  &:hover {
    opacity: 0.7;
  }

  &-facebook {
    background: #3B5998;
  }

  &-twitter {
    background: #55ACEE;
  }

  &-steam {
    background: #000000;
  }

  &-github {
    background: #DCDCDC;
  }

  &--small {
    width: 1rem;
    height: 1rem;
  }

  &--large {
    width: 3rem;
    height: 3rem;
  }
}