.about {
  &-img {
    padding: 1.25rem;
    max-width: 35vh;
    max-height: 35vh;

    &-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}